import axiosInstance from '../utils/http';
import store from '../store';


// 设置基础URL 
const baseURL1 = 'http://diagapi.wwftool.top/api';
const baseURL2 = 'http://diagapi.us.wwftool.top/api';
const baseURL3 = 'http://diagapi.cn.wwftool.top/api';

// 选择 baseURL 的辅助函数  
const chooseBaseURL = (useBaseURL) => {
    return useBaseURL ? baseURL3 : baseURL2;
};

const Triggerdownload = (response) => {
    // 创建 Blob 对象并生成 URL
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = URL.createObjectURL(blob);

    // 从响应头中提取文件名
    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition.split('; ')[1].split('=')[1].replace(/"/g, '');  // 移除引号

    // 创建一个临时 <a> 标签
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    // 将链接添加到文档中并模拟点击
    document.body.appendChild(link);
    link.click();

    // 清理
    document.body.removeChild(link);
    URL.revokeObjectURL(url);  // 释放 URL
};

const documentDown = (url, filename) => {
    // 创建一个临时 <a> 标签
    const link = document.createElement('a');
    link.href = url;

    // 设置下载文件的默认名称（可选）

    link.setAttribute('download', filename);

    // 将链接添加到文档中
    document.body.appendChild(link);

    // 模拟用户点击，触发下载
    link.click();

    // 删除临时链接
    document.body.removeChild(link);
};

//根据本地保存的token验证是否在有效期内
export const getUserInfo = () => {
    return axiosInstance.get(`${baseURL1}/v1/user/userinfo`);
};

//查询放到outcode
export const getOutCodebySn = (limit, sn) => {
    return axiosInstance.get(`${baseURL1}/v1/diag/getOutCodeInfo?limit=${limit}&sn=${sn}`);
};
//下载外码文件
export const downloadOutCodebyId = async (id) => {
    const response = await axiosInstance.get(`${baseURL1}/v1/diag/downloadOutCodeInfo?id=${id}`);
    Triggerdownload(response);// 创建一个 URL 并触发下载   
};


export const downloadVinInfobyId = async (useBaseURL) => {
    const baseURL = chooseBaseURL(useBaseURL);
    const response = await axiosInstance.get(`${baseURL}/v1/diag/downloadVinInfo`);
    Triggerdownload(response);// 创建一个 URL 并触发下载  
};

export const downloadVinInfobyId2 = async (useBaseURL) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/downloadVinInfo2`);
};

export const DownLoadVM = async (useBaseURL, vin) => {
    const baseURL = chooseBaseURL(useBaseURL);
    const response = await axiosInstance.get(`${baseURL}/v1/diag/downloadvm?vin=${vin}`);
    Triggerdownload(response);// 创建一个 URL 并触发下载  
};

export const DownLoadFile = async (useBaseURL, filename) => {
    const baseURL = chooseBaseURL(useBaseURL);
    //const response = await axiosInstance.get(`${baseURL}/v1/diag/DownLoadFile?filename=${filename}`);
    //Triggerdownload(response);// 创建一个 URL 并触发下载  

    const token = localStorage.getItem("authToken");  
    const url = `${baseURL}/v1/diag/DownLoadFile?filename=${filename}&token=${token}`;
    documentDown(url, filename);
};

export const DownLoadFCD = async (useBaseURL, vin) => {
    const baseURL = chooseBaseURL(useBaseURL);
    const response = await axiosInstance.get(`${baseURL}/v1/diag/downloadfcd?vin=${vin}`);
    Triggerdownload(response);// 创建一个 URL 并触发下载  
};

export const DownLoadJM = async (useBaseURL, vin) => {
    const baseURL = chooseBaseURL(useBaseURL);

    const response = await axiosInstance.get(`${baseURL}/v1/diag/downloadjm?vin=${vin}`, {
        responseType: 'blob'  // 设置响应类型为 blob，这样就能正确处理二进制数据
    });
    Triggerdownload(response);// 创建一个 URL 并触发下载  
};

export const DownLoadZip = (useBaseURL, zip) => {
    const baseURL = chooseBaseURL(useBaseURL);
    const url = `${baseURL}/v1/diag/downloadpacked?filename=${zip}`;
    const filename = zip + '.zip';
    documentDown(url, filename);
};


export const TryLoginTool = (useBaseURL, data) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.post(`${baseURL}/v1/diag/trylogintool`, data, { timeout: 120 * 1000 });//单独设置最长等待时间
};

export const getVinStatus = (useBaseURL, vin) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/getVinStatus?vin=${vin}`);
};

export const getVinKey = (useBaseURL, vin) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/getvinkey?vin=${vin}`);
};

export const getHid = (useBaseURL) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/gethid`);
};

export const setHid = (useBaseURL, hidvalue) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/sethid?hid=${hidvalue}`);
};



export const getVinUploadInfo = (useBaseURL, vin) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/getvinuploadinfo?vin=${vin}`);
};

export const MovePackedInfo = (useBaseURL, zip) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/movepackedinfo?filename=${zip}`);
};


export const deleteVinUploadInfo = (useBaseURL, vin) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/deletevinuploadinfo?vin=${vin}`);
};

export const getPackedInfo = (useBaseURL) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/getpackedinfo`);
};

export const CmdUsingXmlTool = (useBaseURL) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/cmdusingxmltool?limit=800&loopcnt=10`);//每次编译800个VIN，生成10个压缩包数据
};

export const CmdUsingXmlToolEx = (useBaseURL) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/cmdusingxmltoolEx`);
};

export const requestVindata = (useBaseURL, vin) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/trydownloadvin?vin=${vin}`);
};

export const getVinInfo = (useBaseURL) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/getVinInfo`);
};

// 查询测试接头
export const getBlackSn = (limit, sn) => {
    return axiosInstance.get(`${baseURL1}/v1/diag/getBlackSn?limit=${limit}&sn=${sn}`);
};

// 添加测试接头
export const addBlackSn = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/diag/setBlackSn`, data);
};

// 删除测试接头
export const deleteBlackSn = (sn) => {
    return axiosInstance.delete(`${baseURL1}/v1/diag/deleteBlackSn/${sn}`);
};

// 查询测试接头
export const getTestSn = () => {
    return axiosInstance.get(`${baseURL1}/v1/diag/getTestSn`);
};
// 添加测试接头
export const addTestSn = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/diag/setTestSn`, data);
};
// 删除测试接头
export const deleteTestSn = (sn) => {
    return axiosInstance.delete(`${baseURL1}/v1/diag/deleteTestSn/${sn}`);
};

// 添加测试模板接头
export const addTestTempSn = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/diag/setTestTempSn`, data);
};
// 删除测试模板接头
export const deleteTestTempSn = (sn) => {
    return axiosInstance.delete(`${baseURL1}/v1/diag/deleteTestTempSn/${sn}`);
};

// 用户登录请求  
export const userLogin = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/user/token`, data);
};

// 保存用户日志格式  
export const userSaveLogFormat = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/user/SaveLogFormat`, data);
};
export const userSaveName = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/user/SaveName`, data);
};
export const userSavePassword = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/user/SavePassword`, data);
};
//查询服务器后台日志数据
export const getPageInfo = (limit, sn, useBaseURL = true) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/setlog/getpagelog?limit=${limit}&sn=${sn}`);
};

export const getPageInfoVin = (limit, vin, useBaseURL = true) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/setlog/getpagelogvin?limit=${limit}&vin=${vin}`);
};

export async function isAuthenticated() {
    const token = localStorage.getItem('authToken');

    if (!token) {
        return false; // 如果没有 token，直接返回 false  
    }

    try {
        const response = await getUserInfo(); // 等待 getUserInfo() 的结果  
        store.commit('setUserData', response.data.userInfo); // 更新 Vuex store  

        if (response.data.hasOwnProperty('LogFormat')) {
            store.commit('setLogFormat', response.data.LogFormat);
        }
        return true; // 验证成功，返回 true  
    } catch (error) {
        console.error('Error validating token:', error); // 记录错误  
        return false; // 验证失败，返回 false  
    }
}


//通用请求接口
export const getSomeOne = (useBaseURL,urlFunc) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/${urlFunc}`);
};

export const setSomeOne = (useBaseURL, datevalue,urlFunc) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/diag/${urlFunc}?data=${datevalue}`);
};

export const Post_setSomeOne = (useBaseURL, data,urlFunc) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.post(`${baseURL}/v1/diag/${urlFunc}`, data);
};

export const DownloadSomeOne = async (useBaseURL,urlFunc) => {
    const baseURL = chooseBaseURL(useBaseURL);
    const response = await axiosInstance.get(`${baseURL}/v1/diag/${urlFunc}`);
    Triggerdownload(response);// 创建一个 URL 并触发下载  
};


//IMMO
export const getImmoMode = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/immo/getimmomode`, data);
};

export const addImmoMode = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/immo/addimmomode`, data);
};

export const updateImmoMode = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/immo/updateimmomode`, data);
};

export const deleteImmoMode = (id) => {
    return axiosInstance.delete(`${baseURL1}/v1/immo/deleteimmomode/${id}`);
};