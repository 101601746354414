<template>
    <el-container class="main-container">
        <el-main class="data-container">

            <el-tabs v-model="radio1">
                <el-tab-pane label="CN Server" name="CN Server"></el-tab-pane>
                <el-tab-pane label="US Server" name="US Server"></el-tab-pane>
            </el-tabs>

            <!-- 24 分栏-->
            <el-row :gutter="20"><!--调整行高-->


                <el-card shadow="hover" class="fixed-height-card">
                    <div class="card-content">
                        <!-- 上半部分 -->
                        <div class="top-section">
                            <h3>安全校验日志</h3>
                            <p>FordOSC_Error.log<br><br></p>
                        </div>
                        <el-divider content-position="right"><el-icon>
                                <StarFilled />
                            </el-icon></el-divider>
                        <!-- 下半部分 -->
                        <div class="bottom-section">
                            <el-button type="primary" class="full-width-button" :loading="btLoading"
                                :disabled="currentUserRole !== '开发'" @click="handleDLSecurityLog">下载文件</el-button>
                        </div>
                    </div>
                </el-card>

                <el-card shadow="hover" class="fixed-height-card">
                    <div class="card-content">
                        <!-- 上半部分 -->
                        <div class="top-section">
                            <h3>更新在线GGP日期</h3>
                            <p>更新后务必使用设备进行验证!!!</p>
                        </div>
                        <el-divider content-position="right">
                            <el-icon>
                                <Sunrise />
                            </el-icon>
                        </el-divider>
                        <!-- 下半部分 -->
                        <div class="bottom-section">
                            <el-row :gutter="10" style="margin-bottom: 5px;">
                                <el-col :span="12">
                                    <el-button type="primary" class="full-width-button"
                                        :disabled="currentUserRole !== '开发'"
                                        @click="handleGetGGPDate('USAFORD')">福特</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button type="primary" class="full-width-button" plain
                                        :disabled="currentUserRole !== '开发'"
                                        @click="handleGetGGPDate('MAZDA')">马自达</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-card>


                <el-card shadow="hover" class="fixed-height-card">
                    <div class="top-section">
                        <div class="card-content">
                            <!-- 上半部分 -->
                            <div class="top-section">
                                <h3>更新MAZDA账号</h3>
                                <p>仅MAZDA开发可执行<br><br></p>
                            </div>
                            <el-divider content-position="right">
                                <el-icon>
                                    <Sunny />
                                </el-icon>
                            </el-divider>
                            <!-- 下半部分 -->
                            <div class="bottom-section">
                                <el-button type="primary" class="full-width-button" :disabled="currentUserRole !== '开发'"
                                    @click="handleGetMacc">填写密码</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>

            </el-row>

            <el-row :gutter="20"> <!--调整行高-->

                <el-card shadow="hover" class="fixed-height-card">
                    <div class="top-section">
                        <div class="card-content">
                            <!-- 上半部分 -->
                            <div class="top-section">
                                <h3>在线so版本控制</h3>
                                <p>上传mycar后,再进行版本修改<br></p>
                            </div>
                            <el-divider content-position="right">
                                <el-icon>
                                    <Ship />
                                </el-icon>
                            </el-divider>
                            <!-- 下半部分 -->
                            <div class="bottom-section">
                                <el-row :gutter="10" style="margin-bottom: 5px;">
                                    <el-col :span="12">
                                        <el-button size="small" type="primary" :disabled="currentUserRole !== '开发'"
                                            @click="handleGetSoVersion('USAFORD')">福特综合</el-button>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-button size="small" type="danger" :disabled="currentUserRole !== '开发'"
                                            @click="handleGetSoVersion('IMM_USAFORD')">福特防盗</el-button>

                                    </el-col>
                                </el-row>
                                <el-row :gutter="10" style="margin-bottom: 5px;">
                                    <el-col :span="12">
                                        <el-button size="small" type="primary" plain :dark="isDark"
                                            :disabled="currentUserRole !== '开发'"
                                            @click="handleGetSoVersion('MAZDA')">马自达综合</el-button>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-button size="small" type="danger" plain :dark="isDark"
                                            :disabled="currentUserRole !== '开发'"
                                            @click="handleGetSoVersion('IMM_MAZDA')">马自达防盗</el-button>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-card>

                <el-card shadow="hover" class="fixed-height-card">
                    <div class="top-section">
                        <div class="card-content">
                            <!-- 上半部分 -->
                            <div class="top-section">
                                <h3>占位2</h3>
                                <p>null<br><br></p>
                            </div>
                            <el-divider content-position="right">
                                <el-icon>
                                    <Lightning />
                                </el-icon>
                            </el-divider>
                            <!-- 下半部分 -->
                            <div class="bottom-section">
                                <el-button type="primary" class="full-width-button"
                                    @click="handleNothing">按钮</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>

                <el-card shadow="hover" class="fixed-height-card">
                    <div class="top-section">
                        <div class="card-content">
                            <!-- 上半部分 -->
                            <div class="top-section">
                                <h3>占位3</h3>
                                <p>null<br><br></p>
                            </div>
                            <el-divider content-position="right">
                                <el-icon>
                                    <MostlyCloudy />
                                </el-icon>
                            </el-divider>
                            <!-- 下半部分 -->
                            <div class="bottom-section">
                                <el-button type="primary" class="full-width-button"
                                    @click="handleNothing">按钮</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-row>
        </el-main>
    </el-container>
    
    <el-dialog v-model="dialogFormVisible2" :title="(useDiagID=='USAFORD') ? '修改福特在线GGP日期' : '修改马自达在线GGP日期'" width="500px">
        <el-form :model="NewGGPDate">
            <el-form-item label="当前:" :label-width="formLabelWidth">
                <el-input v-model="CurGGPDate" autocomplete="off" disabled />
            </el-form-item>
            <el-form-item label="修改:" :label-width="formLabelWidth">
                <el-input v-model="NewGGPDate" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="handleGGPDate">提交</el-button>
            </span>
        </template>
    </el-dialog>


    <el-dialog v-model="dialogFormVisible" title="修改马自达账号密码" width="500px">
        <el-form :model="NewMacc">
            <el-form-item label="当前:" :label-width="formLabelWidth">
                <el-input v-model="CurMacc" autocomplete="off" disabled />
            </el-form-item>
            <el-form-item label="修改:" :label-width="formLabelWidth">
                <el-input v-model="NewMacc" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="handleMacc">提交</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible3" title="在线so版本控制页面" width="80%" :close-on-click-modal="false"
        :fullscreen="true">
        <div class="page-container">
            <!-- 使用 el-row 和 el-col 进行三列布局 -->
            <el-row :gutter="20" type="flex" direction="vertical" justify="center">
                <!-- 参数管理区域 -->
                <el-col :span="10" type="flex" direction="vertical" justify="center">

                    <el-card style="margin-bottom: 10px;" v-loading="btLoading2">

                        <h3>参数管理</h3>
                        <el-tabs v-model="currentVersion" type="card" style="width: 100%; height: 100%;" tab-scrollable>
                            <el-tab-pane v-for="(version, index) in versions" :label="version.name"
                                :name="version.name">
                                <el-table :data="version.params" style="width: 100%; height: 180px;">
                                    <el-table-column label="参数名称" prop="key" min-width="45%"></el-table-column>
                                    <el-table-column label="参数值" prop="value" min-width="40%">
                                        <template #default="scope">
                                            <el-input v-model="scope.row.value" size="small">
                                                <template #append>
                                                    <!-- 提交修改后的内容 -->
                                                    <el-popconfirm title="确认修改?" @confirm="EditParam(scope.row)">
                                                        <template #reference>
                                                            <el-button>
                                                                <el-icon>
                                                                    <Edit />
                                                                </el-icon>
                                                            </el-button>
                                                        </template>
                                                    </el-popconfirm>
                                                </template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" min-width="15%">
                                        <template #default="{ row }">
                                            <el-button @click="removeParam(row)" size="small" type="danger"
                                                disabled>删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-button @click="dialogFormVisible5 = true" type="primary">添加参数</el-button>
                            </el-tab-pane>
                        </el-tabs>
                    </el-card>

                    <!-- 版本管理区域 -->
                    <el-card v-loading="btLoading2">
                        <h3>版本管理</h3>

                        <el-tabs v-model="currentVersion" type="card" style="width: 100%; height: 100%;" tab-scrollable>
                            <el-tab-pane v-for="(version, index) in versions" :label="version.name"
                                :name="version.name">
                                <el-table :data="version.keyValuePairs" style="width: 100%; height: 200px;">
                                    <!-- 调整“键”列宽度，设置最小宽度 -->
                                    <el-table-column label="so" prop="key" min-width="45%" />

                                    <!-- 调整“值”列宽度，设置最小宽度 -->
                                    <el-table-column label="版本" prop="value" min-width="40%">
                                        <template #default="scope">
                                            <el-input v-model="scope.row.value" size="small">

                                                <template #append>
                                                    <!-- 提交修改后的内容 -->

                                                    <el-popconfirm title="确认修改?" @confirm="EditParam2(scope.row)">
                                                        <template #reference>
                                                            <el-button>
                                                                <el-icon>
                                                                    <Edit />
                                                                </el-icon>
                                                            </el-button>
                                                        </template>
                                                    </el-popconfirm>

                                                </template>
                                            </el-input>

                                        </template>
                                    </el-table-column>

                                    <!-- 调整“操作”列宽度，设置最小宽度 -->
                                    <el-table-column label="操作" min-width="15%">
                                        <template #default="{ row }">

                                            <el-popconfirm title="确认删除?" @confirm="removeKeyValue(row)">
                                                <template #reference>
                                                    <el-button size="small" type="danger">删除</el-button>
                                                </template>
                                            </el-popconfirm>

                                        </template>
                                    </el-table-column>
                                </el-table>

                            </el-tab-pane>
                            <el-button @click="dialogFormVisible6 = true" type="primary">新增版本</el-button>
                            <el-button @click="dialogFormVisible7 = true" type="primary"
                                style="margin-left: 10px;">新增so名称</el-button>
                        </el-tabs>

                        <!-- 按钮放在底部 -->
                        <!-- <div style="margin-top: 20px;"> -->

                        <!-- </div> -->
                    </el-card>
                </el-col>

                <!-- 序列号列表区域 -->
                <el-col :span="10">
                    <el-card v-loading="btLoading2">
                        <h3>外发序列号</h3>
                        <el-table :data="serialNumbers" style="width: 100%; height: 630px;">
                            <el-table-column label="序列号" prop="serial" min-width="25%" />
                            <el-table-column label="外发原因" prop="reason" min-width="55%" />
                            <el-table-column label="操作" min-width="20%">
                                <template #default="scope">

                                    <el-popconfirm title="确认删除?" @confirm="DeleteSoVersionSn(scope.$index, scope.row)">
                                        <template #reference>
                                            <el-button size="small" type="danger">删除</el-button>
                                        </template>
                                    </el-popconfirm>

                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button @click="dialogFormVisible4 = true" type="primary">添加序列号</el-button>
                    </el-card>
                </el-col>

            </el-row>
        </div>

        <el-dialog v-model="dialogFormVisible6" title="版本管理" width="500px" :close-on-click-modal="false">
            <el-form-item label="新增版本号:" :label-width="formLabelWidth">
                <el-input v-model="NewVersionValue" autocomplete="off" />
            </el-form-item>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible6 = false">取消</el-button>
                    <el-button type="primary" @click="addVersion">提交</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible7" title="版本管理" width="500px" :close-on-click-modal="false">
            <el-form-item label="新增so名称:" :label-width="formLabelWidth">
                <el-input v-model="NewSoName" autocomplete="off" />
            </el-form-item>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible7 = false">取消</el-button>
                    <el-button type="primary" @click="addNewSoName()">提交</el-button>
                </span>
            </template>
        </el-dialog>


        <el-dialog v-model="dialogFormVisible5" title="添加全局参数" width="500px" :close-on-click-modal="false">
            <el-form :model="NewParm">
                <el-form-item label="参数名称:" :label-width="formLabelWidth">
                    <el-input v-model="NewParm.key" autocomplete="off" />
                </el-form-item>
                <el-form-item label="参数值:" :label-width="formLabelWidth">
                    <el-input v-model="NewParm.value" autocomplete="off" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible5 = false">取消</el-button>
                    <el-button type="primary" @click="addParam">提交</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible4" title="添加so外发接头" width="500px" :close-on-click-modal="false">
            <el-form :model="NewSn">
                <el-form-item label="添加外发序列号:" :label-width="formLabelWidth">
                    <el-input v-model="NewSn.serial" autocomplete="off" maxlength="12" show-word-limit />
                </el-form-item>
                <el-form-item label="外发原因说明:" :label-width="formLabelWidth">
                    <el-input v-model="NewSn.reason" autocomplete="off" type="textarea" :rows="2" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible4 = false">取消</el-button>
                    <el-button type="primary" @click="addSerial">提交</el-button>
                </span>
            </template>
        </el-dialog>

    </el-dialog>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import { DownLoadFile, getSomeOne, setSomeOne, Post_setSomeOne } from '../api/users';
import { StarFilled, Sunrise, Sunny, Ship, MostlyCloudy, Lightning, Edit } from '@element-plus/icons-vue';
import { ElMessage, ElNotification } from 'element-plus'
import { useStore } from 'vuex';
export default {

    components: {
        StarFilled, Sunrise, Sunny, Ship, MostlyCloudy, Lightning, Edit
    },

    setup() {
        const useDiagID = ref('');
        const radio1 = ref('CN Server');
        const CurGGPDate = ref('');
        const NewGGPDate = ref('');
        const CurMacc = ref('');
        const NewMacc = ref('');
        const NewVersionValue = ref('');
        const NewSoName = ref('');
        const DiagID = ref('');//区分车型
        const btLoading = ref(false);
        const btLoading2 = ref(false);
        const dialogFormVisible = ref(false);
        const dialogFormVisible2 = ref(false);
        const dialogFormVisible3 = ref(false);
        const currentUserRole = ref('');//区分测试还是开发

        const dialogFormVisible4 = ref(false);//用于so版本控制中的提交外发序列号
        const dialogFormVisible5 = ref(false);//个性化参数设置

        const dialogFormVisible6 = ref(false);//版本管理 - 添加版本按钮
        const dialogFormVisible7 = ref(false);//版本管理 - 添加键值对按钮
        //模拟数据/////////////////////////////////////////////////////


        const params = ref([]);

        const versions = ref([]);
        // 默认选中第一个版本
        const currentVersion = ref('');
        const serialNumbers = ref([]);

        const NewSn = reactive({
            serial: '',
            reason: ''
        })
        const NewParm = reactive({
            key: '',
            value: '',
            valueori: ''
        })


        // 组件挂载时发起请求  
        onMounted(async () => {
            const store = useStore();
            currentUserRole.value = store.state.userData?.job;
        });

        const addParam = () => {
            if (!NewParm.key || !NewParm.value) {
                ElMessage.error('请填写所有字段');
                return;
            }

            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            const form = { key: NewParm.key, value: NewParm.value, "DiagID": DiagID.value, version: currentVersion.value };
            try {
                Post_setSomeOne(useBaseURL, form, 'SoVersionEditParam').then(response => {

                    ElMessage.success('已同步到CN/US服务器中');
                    params.value.unshift(form);
                    dialogFormVisible5.value = false;
                })
                    .catch(error => {
                        ElMessage.error('设置失败！错误原因:' + error);
                    });
            } catch (error) {
                ElMessage.error('设置失败！错误原因:' + error);
            }

            NewParm.key = '';
            NewParm.value = '';
        };

        const removeParam = (param) => {
            //目前屏蔽删除，避免误操作，需要删除，直接在服务器后台修改
        };

        const addVersion = () => {
            if (!NewVersionValue) {
                ElMessage.error('版本号不能为空');
                return;
            }

            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            const form = { "NewVersion": NewVersionValue.value, "DiagID": DiagID.value };
            try {
                Post_setSomeOne(useBaseURL, form, 'SoVersionAddVersion').then(response => {

                    ElMessage.success('已同步到CN/US服务器中');
                    versions.value.unshift(
                        {
                            name: NewVersionValue.value,
                            keyValuePairs: [],
                            params: response.data.params
                        });
                    console.log(versions.value);
                    dialogFormVisible6.value = false;
                })
                    .catch(error => {
                        ElMessage.error('设置失败！错误原因:' + error);
                    });
            } catch (error) {
                ElMessage.error('设置失败！错误原因:' + error);
            }
        };

        const addNewSoName = () => {
            if (!NewSoName) {
                ElMessage.error('so名称不能为空');
                return;
            }

            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            const form = { key: NewSoName.value, version: currentVersion.value, DiagID: DiagID.value };
            /////////////////////////////////////////////////////////////////////////////////////
            try {
                Post_setSomeOne(useBaseURL, form, 'SoVersion2EditParam').then(response => {

                    ElMessage.success('已同步到CN/US服务器中');
                    const version = versions.value.find((v) => v.name === currentVersion.value);
                    version.keyValuePairs.push({ key: NewSoName.value, value: response.data.value });

                    dialogFormVisible7.value = false;
                })
                    .catch(error => {
                        ElMessage.error('设置失败！错误原因1:' + error);
                    });
            } catch (error) {
                ElMessage.error('设置失败！错误原因2:' + error);
            }
        };

        const removeKeyValue = (keyValue) => {
            const form = { version: currentVersion.value, key: keyValue.key, "DiagID": DiagID.value };
            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            try {
                Post_setSomeOne(useBaseURL, form, 'SoVersionRemoveKeyValue').then(response => {

                    ElMessage.success('已同步到CN/US服务器中');
                    const version = versions.value.find((v) => v.name === currentVersion.value);
                    const index = version.keyValuePairs.indexOf(keyValue);
                    if (index !== -1) {
                        version.keyValuePairs.splice(index, 1);
                    }
                })
                    .catch(error => {
                        ElMessage.error('设置失败！错误原因:' + error);
                    });
            } catch (error) {
                ElMessage.error('设置失败！错误原因:' + error);
            }
        };

        const addSerial = async () => {
            // 判断是否填写了所有字段
            if (!NewSn.serial || !NewSn.reason) {
                ElMessage.error('请填写所有字段');
                return;
            }

            if (NewSn.serial.length != 12) {
                ElMessage.error('序列号长度不足12位');
                return;
            }
            dialogFormVisible4.value = false;
            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            const form = { serial: NewSn.serial, reason: NewSn.reason, DiagID: DiagID.value };
            try {
                Post_setSomeOne(useBaseURL, form, 'SoVersionAddSn').then(response => {

                    ElMessage.success('已同步到CN/US服务器中');
                    serialNumbers.value.unshift(form);
                })
                    .catch(error => {
                        ElMessage.error('设置失败！错误原因:' + error);
                    });
            } catch (error) {
                ElMessage.error('设置失败！错误原因:' + error);
            }

            NewSn.serial = '';
            NewSn.reason = '';
        };


        const EditParam = async (row) => {
            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            const form = { key: row.key, value: row.value, "DiagID": DiagID.value,version: currentVersion.value };

            try {
                Post_setSomeOne(useBaseURL, form, 'SoVersionEditParam').then(response => {
                    ElMessage.success('已同步到CN/US服务器中');
                })
                    .catch(error => {
                        ElMessage.error('设置失败！错误原因:' + error);
                    });
            } catch (error) {
                ElMessage.error('设置失败！错误原因:' + error);
            }
        };

        const EditParam2 = async (row) => {
            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            const form = { key: row.key, value: row.value, version: currentVersion.value, DiagID: DiagID.value };

            try {
                Post_setSomeOne(useBaseURL, form, 'SoVersion2EditParam').then(response => {
                    ElMessage.success('已同步到CN/US服务器中');
                })
                    .catch(error => {
                        ElMessage.error('设置失败！错误原因:' + error);
                    });
            } catch (error) {
                ElMessage.error('设置失败！错误原因:' + error);
            }
        };

        const DeleteSoVersionSn = async (index, row) => {
            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            const form = { serial: row.serial, 'DiagID': DiagID.value };

            try {
                Post_setSomeOne(useBaseURL, form, 'DeleteSoVersionSn').then(response => {

                    ElMessage.success('已同步到CN/US服务器中');
                    serialNumbers.value.splice(index, 1);
                })
                    .catch(error => {
                        ElMessage.error('删除失败！错误原因:' + error);
                    });
            } catch (error) {
                ElMessage.error('删除失败！错误原因:' + error);
            }
        };

        ////////////////////////////////////////////////////////////////

        //相应函数
        const handleDLSecurityLog = async () => {
            btLoading.value = true;
            ElMessage({ type: 'success', message: '请求下载中...', })
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                await DownLoadFile(useBaseURL, "FordOSC_Error.log");

            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
            btLoading.value = false;
        };

        const handleGetGGPDate = async (DiagType) => {
            try {
                dialogFormVisible2.value = true;//显示窗口
                useDiagID.value = DiagType;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
               
                if (DiagType == 'USAFORD') {
                    const response = await getSomeOne(useBaseURL, 'getGGPDate');
                    CurGGPDate.value = response.data.GGPDate;
                    NewGGPDate.value = response.data.GGPDate;
                }
                else if (DiagType == 'MAZDA') {
                    const response2 = await getSomeOne(useBaseURL, 'getGGPDateMazda');
                    CurGGPDate.value = response2.data.GGPDate;
                    NewGGPDate.value = response2.data.GGPDate;
                }

            } catch (error) {
                ElMessage({ type: 'warning', message: '获取数据失败，请重试' });
                dialogFormVisible2.value = false;
            }
        };

        const handleGGPDate = async () => {
            try {
                if (NewGGPDate.value.length !== 10) {
                    ElMessage({ type: 'error', message: 'GGP日期填充格式不正确', })
                    return;
                }

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                dialogFormVisible2.value = false;
                if (useDiagID.value == 'USAFORD') {
                    await setSomeOne(useBaseURL, NewGGPDate.value, 'setGGPDate');
                }
                else if (useDiagID.value == 'MAZDA') {
                    await setSomeOne(useBaseURL, NewGGPDate.value, 'setGGPDateMazda');
                }

                ElMessage({ type: 'success', message: '设置GGP日期成功(同步国内海外双服务器)' });
            } catch (error) {
                ElMessage({ type: 'warning', message: '设置GGP日期失败，请重试' });
                dialogFormVisible2.value = false;
            }
        }

        const handleGetMacc = async () => {
            try {
                dialogFormVisible.value = true;//显示窗口

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                const response = await getSomeOne(useBaseURL, 'getMazdaAccount');
                CurMacc.value = response.data.Acc;
                NewMacc.value = response.data.Acc;
            } catch (error) {
                ElMessage({ type: 'warning', message: '获取账号失败，请重试' });
                dialogFormVisible.value = false;
            }
        };

        const handleMacc = async () => {
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                dialogFormVisible.value = false;
                await setSomeOne(useBaseURL, NewMacc.value, 'setMazdaAccount');
                ElMessage({ type: 'success', message: '设置mazda账号密码成功(同步国内海外双服务器)' });
            } catch (error) {
                ElMessage({ type: 'warning', message: '设置mazda账号密码失败，请重试' });
                dialogFormVisible.value = false;
            }
        }


        const handleGetSoVersion = async (param) => {
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                btLoading2.value = true;
                dialogFormVisible3.value = true;//显示窗口
                //请求服务器获取最新数据并填充
                const response = await getSomeOne(useBaseURL, 'getSoVersionInfo?DiagID=' + param);

                btLoading2.value = false;
                serialNumbers.value = response.data.serialNumbers;//外发序列号列表
                versions.value = response.data.versions;//按版本区分 so列表 + 控制参数列表
                // params.value = response.data.params;//总控制参数列表

                if (versions.value.length > 0) {
                    currentVersion.value = versions.value[0].name;  // 选中第一个版本
                } else {
                    currentVersion.value = ''; // 为空时避免报错
                }
                DiagID.value = param;//通过界面按钮设置软件包ID



            } catch (error) {
                ElMessage({ type: 'warning', message: '失败，请重试' });
                dialogFormVisible3.value = false;
            }
        };

        const handleNothing = async () => {
            ElNotification({
                title: '待开发',
                message: '占个位先...',
                duration: 2000,
            })
        };

        return {
            radio1, btLoading, dialogFormVisible, dialogFormVisible2, dialogFormVisible3, dialogFormVisible4, dialogFormVisible5, dialogFormVisible6, dialogFormVisible7, CurGGPDate, NewGGPDate, CurMacc, NewMacc,
            handleDLSecurityLog, handleNothing, handleGetGGPDate, handleGGPDate, handleGetMacc, handleMacc, handleGetSoVersion,
            params, versions, serialNumbers, addParam, removeParam, addVersion, addNewSoName, NewSoName, removeKeyValue, addSerial, DeleteSoVersionSn, EditParam, EditParam2, currentVersion,
            NewSn, NewParm, NewVersionValue, DiagID, btLoading2, currentUserRole,useDiagID
        };
    },
};
</script>

<style scoped>
.el-row {
    /* 调整行与行之间的间隔 */
    margin-bottom: 30px;
}

/* 卡片容器设置 */
.fixed-height-card {
    margin: 10px;
    height: 233px;
    width: 220px;
    /* 根据实际需求调整 */
    /* display: flex; */
    /* flex-direction: column; */
}

/* 内容区域填充 */
.card-content {
    /* display: flex; */
    /* flex-direction: column; */
    /* flex: 1; */
    padding: 0px;
    /* 控制卡片内部的内边距 */
    margin: 0px;
    /* 控制 card-content 和卡片边缘之间的外边距 */
}

/* 上半部分样式 */
.top-section {
    flex: 1;
}

/* 下半部分样式 */
.bottom-section {
    /* flex: 1; */
    /* 自动填充剩余空间 */
    /* padding-top: 15px; */
    /* overflow-y: auto; */
    /* 内容溢出时显示滚动条 */
}

.full-width-button {
    width: 100%;
    /* 使按钮的宽度占满整个容器 */
    height: 50px;
    /* 可选: 设置按钮的高度 */
}

.top-section>h3:nth-child(1) {
    margin-top: 0px;
}


.page-container {
    padding: 20px;
}

el-card {
    margin-bottom: 20px;
}
</style>