<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}

//将回调延迟200毫秒执行，从而避免频繁弹出报错黑框。在App.vue和main.js中应用这个修改可以有效地解决此问题
// app.vue写在script里面  main.js写在app挂在完之后
const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 200);
        super(callback);
    }
}


</script>

<style>
@import './assets/global.css'; /* 引入全局样式文件 */
</style>
