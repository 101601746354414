<template>
    <el-container class="main-container">
        <el-main class="data-container">

            <div class="serial-number-container">
                <el-autocomplete v-model="serialNumber" :fetch-suggestions="querySearch" clearable
                    class="inline-input w-100" placeholder="Please input vin" @select="handleSelect" maxlength="17"
                    show-word-limit type="text" />
                <el-button type="primary" @click="fetchData">查询</el-button>
            </div>

            <el-tabs v-model="radio1" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane label="CN Server" name="CN Server"></el-tab-pane>
                <el-tab-pane label="US Server" name="US Server"></el-tab-pane>

                <el-row v-loading="loading">
                    <el-col :span="3">
                        <el-statistic title="账号登录状态" :value="loggedin" :value-style="{
                            color: loggedin === '已登录' ? '#61bc84' : '#fd5732',
                            fontFamily: 'Poppins, Arial, sans-serif',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            fontStyle: 'PingFang SC'
                        }" />
                        <el-button size="small" type="" :icon="Smoking" circle @click="dialogFormVisible = true" />

                        <el-dialog v-model="dialogFormVisible" title="提交在线账号" width="500px">
                            <el-form :model="form">
                                <el-form-item label="账号:" :label-width="formLabelWidth">
                                    <el-input v-model="form.acc" autocomplete="off" />
                                </el-form-item>
                                <el-form-item label="密码:" :label-width="formLabelWidth">
                                    <el-input v-model="form.password" autocomplete="off" />
                                </el-form-item>
                            </el-form>
                            <template #footer>
                                <span class="dialog-footer">
                                    <el-button @click="dialogFormVisible = false">取消</el-button>
                                    <el-button type="primary" @click="handleSubmit">提交</el-button>
                                </span>
                            </template>
                        </el-dialog>

                        <el-button size="small" type="" :icon="EditPen" circle @click="handleGetHID()" />

                        <el-dialog v-model="dialogFormVisible2" title="修改登录账号使用的HID" width="500px">
                            <el-form :model="NewHid">
                                <el-form-item label="当前:" :label-width="formLabelWidth">
                                    <el-input v-model="CurHid" autocomplete="off" disabled />
                                </el-form-item>
                                <el-form-item label="修改:" :label-width="formLabelWidth">
                                    <el-input v-model="NewHid" autocomplete="off" />
                                </el-form-item>
                            </el-form>
                            <template #footer>
                                <span class="dialog-footer">
                                    <el-button @click="dialogFormVisible2 = false">取消</el-button>
                                    <el-button type="primary" @click="handleSubmitHID">提交</el-button>
                                </span>
                            </template>
                        </el-dialog>

                    </el-col>
                    <el-col :span="3">
                        <el-statistic title="收集总数" :value="collectTotal" />
                    </el-col>
                    <!-- 8 -->
                    <el-col :span="3">
                        <el-statistic title="未下载总数" :value="notDownloaded" />

                        <el-popconfirm title="点击下载未爬取的所有VIN数据" @confirm="handleDownLoadVin()">
                            <template #reference>
                                <el-button size="small" type="" :icon="Download" circle />
                            </template>
                        </el-popconfirm>

                    </el-col>
                    <!-- 12 -->
                    <el-col :span="3">
                        <el-statistic title="已爬取总数" :value="downloads" />
                    </el-col>
                    <!-- 16 -->
                    <el-col :span="3">
                        <el-statistic title="数据库容量" :value="dbcapacity" />
                    </el-col>
                    <!-- 20 -->
                    <el-col :span="3">
                        <el-statistic title="今日收集" :value="todayCollect" />
                    </el-col>
                    <!-- 24 -->
                    <el-col :span="3">
                        <el-statistic title="今日爬取" :value="todayUpdate" />

                        <el-popconfirm title="后台启动爬取操作,仅爬取2024+的VIN" @confirm="handleDownLoadVin2()">
                            <template #reference>
                                <el-button size="small" type="" :icon="Download" circle />
                            </template>
                        </el-popconfirm>

                    </el-col>
                </el-row>

                <el-row> <el-col :span="24"></el-col></el-row>

                <el-row v-loading="loading">
                    <el-col :span="3">
                        <el-statistic title="未匹配模板数" :value="notmatchDeliver" />

                        <el-popconfirm title="确定要下载所有未匹配模板的 VIN 码吗？" @confirm="handleDownLoadVin3">
                            <template #reference>
                                <el-button size="small" :icon="Download" circle style="margin-right: -12px;" />
                            </template>
                        </el-popconfirm>

                        <el-popconfirm title="确定要清空当前记录的数据吗？此操作不可撤销！" @confirm="handleClearVin">
                            <template #reference>
                                <el-button size="small" :icon="Delete" circle />
                            </template>
                        </el-popconfirm>


                    </el-col>
                </el-row>

                <el-row> <el-col :span="24"></el-col></el-row>
                <el-row :gutter="0" style="margin-top: 15px;">

                    <el-table :data="filterTableData" v-loading="loading2">
                        <el-table-column v-for="column in columns" :key="column.prop" :prop="column.prop"
                            :label="column.label" :width="column.width" header-align="center" align="center" />
                        <el-table-column>

                            <template #default="scope">

                                <el-popconfirm title="爬取数据" @confirm="handleRequest(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="success" :loading="btLoading"
                                            style="min-width: 60px;">爬取 </el-button>
                                    </template>
                                </el-popconfirm>

                                <el-popconfirm title="下载VM文件" @confirm="handleDownLoad(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="success"
                                            style="background-color: #71c4ef;min-width: 60px;">下载VM</el-button>
                                    </template>
                                </el-popconfirm>

                                <el-popconfirm title="下载加密文件" @confirm="handleDownLoad2(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="danger"
                                            style="background-color: #FF6600;min-width: 60px;">下载加密</el-button>
                                    </template>

                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>


                <el-row :gutter="0" style="margin-top: 15px;">

                    <el-table :data="tableData2" v-loading="loading2">
                        <el-table-column v-for="column in columns2" :key="column.prop" :prop="column.prop"
                            :label="column.label" :width="column.width" header-align="center" align="center" />
                        <el-table-column></el-table-column>

                        <el-table-column>

                            <template #default="scope">

                                <el-popconfirm title="仅处理投诉时使用，请勿用于开发调试!!!" @confirm="handleChangeDiagMod(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="success" plain
                                            style="min-width: 60px;margin-left: 153px;">在线离线互转</el-button>
                                    </template>

                                </el-popconfirm>
                            </template>
                        </el-table-column>

                    </el-table>
                </el-row>

            </el-tabs>
        </el-main>
    </el-container>
</template>

<script>


import { ref, computed, onMounted, reactive } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import { getVinInfo, downloadVinInfobyId, downloadVinInfobyId2, getVinStatus, requestVindata, DownLoadVM, DownLoadFCD, DownLoadJM, TryLoginTool, getVinKey, getHid, setHid, DownloadSomeOne, setSomeOne, Post_setSomeOne } from '../api/users';
import { Download, Smoking, EditPen, Delete } from '@element-plus/icons-vue';
export default {
    setup() {
        // 列配置，这里你可以根据需要动态设置或静态定义  
        const formLabelWidth = '60px'
        const dialogFormVisible = ref(false);
        const dialogFormVisible2 = ref(false);
        const CurHid = ref('Waiting for network request');
        const NewHid = ref('');
        const form = reactive({
            acc: '',
            password: ''
        })
        const columns = ref([
            { prop: 'Vin', label: 'Vin', width: '200' },
            { prop: 'status', label: '状态', width: '100' },
            { prop: 'time', label: '时间', width: '180' },
            { prop: 'deliver', label: '模板', width: '130' },
            { prop: 'message', label: '信息' },
        ]);

        const columns2 = ref([
            { prop: 'VinTemp', label: 'Vin模板', width: '200' },
            { prop: 'DiagMod', label: '诊断分支', width: '100' },
        ]);

        const tableData = ref([]);
        const tableData2 = ref([]);
        const search = ref('');
        const filterTableData = computed(() =>
            tableData.value.filter(
                (data) =>
                    !search.value ||
                    data.Vin.toLowerCase().includes(search.value.toLowerCase())
            )
        );
        const cityData = ref({
            'CN Server': [],
            'US Server': []
        });

        const radio1 = ref('CN Server')
        const collectTotal = ref(0); // 收集总数  
        const notDownloaded = ref(0); // 未下载总数  
        const notmatchDeliver = ref(0); // 未匹配模板数  
        const todayCollect = ref(0); // 今日收集  
        const todayUpdate = ref(0); // 今日更新  
        const downloads = ref(0); // 已下载总数  
        const dbcapacity = ref(''); // 数据库容量
        const loggedin = ref('未知');//账号登录状态

        const loading = ref(false)  // 定义 按钮请求网络时等待的 状态
        const loading2 = ref(false)
        const btLoading = ref(false)
        const serialNumber = ref('');
        const currentPage = ref(1);
        const pageSize = ref(20); // 每页显示N条数据为例  

        const isValidLength = ref(true);
        //const recentQueries = ref([]);
        const recentQueries = ref(JSON.parse(localStorage.getItem('recentQueriesVinList')) || []);

        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = currentPage.value * pageSize.value;
            return cityData.value[radio1.value].slice(start, end);
        });

        //相应函数
        const handleClick = async (tab, event) => {
            loading.value = true;
            const useBaseURL = (tab.paneName === 'CN Server') ? true : false;
            const response = await getVinInfo(useBaseURL);

            collectTotal.value = response.data.collectTotal;
            notDownloaded.value = response.data.notDownloaded;
            notmatchDeliver.value = response.data.notmatchDeliver;
            loggedin.value = response.data.loggedin;
            todayCollect.value = response.data.todayCollect;
            todayUpdate.value = response.data.todayUpdate;
            downloads.value = response.data.downloads;
            dbcapacity.value = response.data.dbcapacity;
            loading.value = false;
        }

        // 组件挂载时发起请求  
        onMounted(async () => {
            loading.value = true;
            const response = await getVinInfo(true);
            collectTotal.value = response.data.collectTotal;
            notDownloaded.value = response.data.notDownloaded;
            notmatchDeliver.value = response.data.notmatchDeliver;
            loggedin.value = response.data.loggedin;
            todayCollect.value = response.data.todayCollect;
            todayUpdate.value = response.data.todayUpdate;
            downloads.value = response.data.downloads;
            dbcapacity.value = response.data.dbcapacity;
            loading.value = false;
        });

        const handleDownLoadVin = async () => {
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                await downloadVinInfobyId(useBaseURL);//下载文件
            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
        };

        const handleDownLoadVin2 = async () => {
            try {

                if (loggedin.value != "已登录") {
                    return ElMessage({ type: 'error', message: '原厂账号未登录,请先完成登录操作!!!', });
                }

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                await downloadVinInfobyId2(useBaseURL);//后台自动下载
                ElMessage({ type: 'success', message: '后台自动下载:请求成功', })
            } catch (error) {
                ElMessage({ type: 'error', message: '12小时后会再次开放操作', })
            }
        };

        const handleDownLoadVin3 = async () => {
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                await DownloadSomeOne(useBaseURL, 'downloadNotMatchVinDeliv');//下载文件
            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
        };
        const handleClearVin = async () => {
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                await setSomeOne(useBaseURL, '', 'deleteNotMatchVinDeliv');
                notmatchDeliver.value = '0';
            } catch (error) {
                ElMessage({ type: 'error', message: '清除失败，请重试', })
            }
        };


        const handleRequest = async (index, row) => {
            try {
                if (row.status == "OK") {
                    return ElMessage({ type: 'error', message: '数据已存在,无需爬取!!!', });
                }
                if (loggedin.value != "已登录") {
                    return ElMessage({ type: 'error', message: '账号未登录,请联系开发登录爬虫账号', });
                }

                const value = row.Vin;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                btLoading.value = true;
                await requestVindata(useBaseURL, value);
                ElMessage({ type: 'success', message: '已发送请求,建议在20后再次查询结果', })
                await delay(20000);//20秒
            } catch (error) {
                if (error.status == 400) {
                    ElMessage({ type: 'error', message: '未登录账号!!!', })
                }
                else {
                    ElMessage({ type: 'error', message: '请求资源失败，请重试', })
                }
            }
            finally {
                btLoading.value = false;
            }
        };

        const handleDownLoad = async (index, row) => {
            try {
                if (row.status == "FINISH") {
                    return ElMessage({ type: 'error', message: '先在<数据转存>菜单中删除上传记录.', });
                }
                else if (row.status != "OK") {
                    return ElMessage({ type: 'error', message: '数据不存在,请先完成爬取操作', });
                }

                const value = row.Vin;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                await DownLoadVM(useBaseURL, value);
                await DownLoadFCD(useBaseURL, value);
                ElMessage({ type: 'success', message: '下载成功', })
            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
        };

        const handleChangeDiagMod = async (index, row) => {
            try {
                if (row.DiagMod == "江铃软件") {
                    return ElMessage({ type: 'error', message: '江铃软件不支持转换', });
                }
                else if (row.DiagMod == "在线结构-仅模板") {
                    return ElMessage({ type: 'error', message: '仅模板类型,不支持转换', });
                }
                else if ((row.DiagMod != "在线结构") && (row.DiagMod != "离线结构")) {
                    return ElMessage({ type: 'error', message: '数据不存在,不支持转换', });
                }

                const value = row.Vin;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                const Mod = (row.DiagMod == "在线结构") ? "FDRS" : "IDS";
                const form = { "Vin": tableData.value[0].Vin, "DiagMod": Mod, "deliver": tableData.value[0].deliver };
                Post_setSomeOne(useBaseURL, form, 'ChangeDiagMod').then(response => {
                    tableData2.value = response.data.tableData2;
                    if (useBaseURL) {
                        ElMessage.success('已同步到CN服务器中');
                    }
                    else {
                        ElMessage.success('已同步到US服务器中');
                    }
                    
                })
                    .catch(error => {
                        ElMessage.error('sDiagMod');
                    });
            } catch (error) {
                ElMessage({ type: 'error', message: '修改数据失败，请重试', })
            }
        };

        const handleDownLoad2 = async (index, row) => {
            try {
                if (row.status == "FINISH") {
                    return ElMessage({ type: 'error', message: '先在<数据转存>菜单中删除上传记录.', });
                }
                else if (row.status != "OK") {
                    return ElMessage({ type: 'error', message: '数据不存在,请先完成爬取操作', });
                }

                const value = row.Vin;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                await DownLoadJM(useBaseURL, value);//下载加密文件

                ElMessage({ type: 'success', message: '下载成功', })

                const response = await getVinKey(useBaseURL, value);
                ElMessageBox.alert(response.data.vinkey, 'VinKey', {
                    confirmButtonText: 'OK',
                })

            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
        };

        const fetchData = async () => {
            const serialNumberValue = (serialNumber.value && serialNumber.value.length === 17) ? serialNumber.value : "all";
            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            if (!serialNumber.value || serialNumber.value.length !== 17) {
                ElMessage({ type: 'error', message: '请输入一个17位的Vin', })
                return;
            }
            loading2.value = true;  //开始加载
            const response = await getVinStatus(useBaseURL, serialNumberValue);
            if (response.data.isnull) {
                ElMessage({ type: 'warning', message: '服务器无该VIN数据', });
            }
            else {
                columns.value = response.data.columns;
                tableData.value = response.data.tableData;
                columns2.value = response.data.columns2;
                tableData2.value = response.data.tableData2;
            }

            try {
                if (serialNumber.value.length === 17) {
                    updateRecentQueries(serialNumberValue);
                }
            } catch (error) {
                alert('请求失败，请重试');
            } finally {
                loading2.value = false;
            }
        };

        const handleSubmit = async () => {
            try {
                // 验证输入、调用API等逻辑
                if (!form.acc || !form.password) {
                    ElMessage({ type: 'warning', message: '账号、密码不能为空' });
                    return;
                }
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                const olacc = { acc: form.acc, password: form.password };
                loading.value = true;
                dialogFormVisible.value = false;
                await TryLoginTool(useBaseURL, olacc);
                loading.value = false;
                ElMessage({ type: 'success', message: '已同步到CN/US服务器中' });
                loggedin.value = '已登录';
                form.acc = '';
                form.password = '';
            } catch (error) {
                loading.value = false;
                ElMessage({ type: 'warning', message: '请求登录账号失败，请重试' });
            }
        };


        const handleSubmitHID = async () => {
            try {
                if (NewHid.value.length !== 28) {
                    ElMessage({ type: 'error', message: '请输入一个28位的Hid', })
                    return;
                }

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                dialogFormVisible2.value = false;
                await setHid(useBaseURL, NewHid.value);
                ElMessage({ type: 'success', message: '设置HID成功' });
            } catch (error) {
                ElMessage({ type: 'warning', message: '设置HID失败，请重试' });
                dialogFormVisible2 = false;
            }
        }

        const handleGetHID = async () => {
            try {
                dialogFormVisible2.value = true;//显示窗口

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                const response = await getHid(useBaseURL);
                CurHid.value = response.data.hid;
                NewHid.value = response.data.hid;
            } catch (error) {
                ElMessage({ type: 'warning', message: '获取HID失败，请重试' });
                dialogFormVisible2 = false;
            }
        };

        // 更新查询记录的函数
        const updateRecentQueries = (query) => {
            // 检查查询是否已存在于数组中，注意这里要比较对象的 value 属性  
            const index = recentQueries.value.findIndex(item => item.value === query);
            if (index !== -1) {
                // 如果已存在，则将其从数组中移除  
                recentQueries.value.splice(index, 1);
            }
            // 将查询（作为对象）添加到数组头部，并确保数组长度不超过3
            recentQueries.value.unshift({ value: query }); // 使用unshift而不是push  
            if (recentQueries.value.length > 3) {
                recentQueries.value.pop(); // 使用pop移除末尾元素，而不是shift  
            }

            localStorage.setItem('recentQueriesVinList', JSON.stringify(recentQueries.value));
        };

        // 在 handleSelect 函数中，用户选择建议后更新最近查询记录  
        const handleSelect = (item) => {
            updateRecentQueries(item.value);
            // ... 其他处理选择的代码 ...  
        };


        // 将 recentQueries 添加到 fetch-suggestions 中  
        const querySearch = (queryString, cb) => {

            cb(recentQueries.value);
        };
        return {
            columns, tableData, columns2, tableData2,
            filterTableData,
            currentPage,
            pageSize,
            paginatedData,
            serialNumber, recentQueries,
            isValidLength,
            fetchData,
            loading, loading2, btLoading,
            radio1,
            cityData,
            querySearch, search,
            handleSelect,
            handleClick, handleDownLoad, handleDownLoad2,
            Download, Smoking, EditPen, Delete, handleDownLoadVin, handleDownLoadVin2, handleDownLoadVin3, handleRequest,
            collectTotal, notDownloaded, notmatchDeliver, todayCollect, todayUpdate, downloads, dbcapacity, loggedin,
            dialogFormVisible, dialogFormVisible2, formLabelWidth, form, handleSubmit, ElMessageBox,
            CurHid, NewHid, handleGetHID, handleSubmitHID,
            handleClearVin, handleChangeDiagMod
        };
    },
};
</script>

<style scoped>
/* 数据显示栏的样式 */
.main-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.header-container {
    text-align: center;
}

.el-table {
    border: 1px solid #ebeef5;
    border-radius: 4px;
}

.el-table th,
.el-table td {
    text-align: left;
    padding: 12px 20px;
}

.serial-number-container {
    width: 350px;
    /* margin-top: 1px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

.el-col {
    text-align: center;
}

.conan-title {
    font-size: 0.9rem;
    color: rgba(255, 0, 0, 0.81);
    text-align: center;
    margin: 20px 0;
    transition: transform 0.3s ease;
    font-family: "Poppins", sans-serif;
    text-transform: none;
}
</style>